import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux';
import SEO from '../components/seo'
import ScrollAnimation from 'react-animate-on-scroll';
import { tablet, desktop } from '../breakpoints';
import aboutBanner from '../images/aboutus-banner.jpg';
import susie from '../images/susie.png';
import podcast from '../images/podcast.png';
import podcast2 from '../images/podcast2.png';
import podcast3 from '../images/podcast3.png';
import sleeve from '../images/sleeve.png';
import sleeve2 from '../images/sleeve2.png';
import sleeve3 from '../images/sleeve3.png';
import archive1 from '../images/archive1.png';
import archive2 from '../images/archive2.png';
import archive3 from '../images/archive3.png';
import archive4 from '../images/archive4.png';
import archive5 from '../images/archive5.png';
import podcastIntro1 from '../images/podcastIntro1.png';
import podcastIntro2 from '../images/podcastIntro2.png';
import podcastIntro3 from '../images/podcastIntro3.png';

class ConverstationsPage extends Component {


	render() { 

	
		return (
			<Page>

				<SEO title="Podcasts" keywords={[`radio`, `application`, `react`]} />

				


                <LiveInfo>
					

					<InnerBody>

                    
                        <TitleB>Conversations<br/>with Susie Pearl</TitleB>
                        
                        
                        <Container>
                            <p>A series of podcasts from Susie Pearl talking 
                                to inspiring guests about a range of subjects that 
                                matter to us all right now.</p>
                                <p>Join us here to listen to some relevant 
                                conversations to soothe the soul, inspire the 
                                spirit and expand the mind.</p>

                                <div class="introImages">
                                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}><img src={podcastIntro3} className="start" /></ScrollAnimation>
                                    <span><ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={500} ><img src={podcastIntro1}  /></ScrollAnimation></span>
                                    <span><ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={1000} ><img src={podcastIntro2}  /></ScrollAnimation></span>
                                </div>
                        </Container>

                    <div class="cardWrapper">                       
						
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                       <Card>
                            <div>
                            <img src={podcast} />
                            </div>
                            <div>
                         
                                <div>
                                    <div>
                                    <a href="https://anchor.fm/susiepearl/episodes/Episode-1--David-Lynch-e5akcm"><img src={sleeve} /></a>
                                    </div>
                                    <div>
                                        <div>
                                            <label>Episode 1</label>
                                            <h3>David Lynch</h3>
                                        </div>
                                        <a href="https://anchor.fm/susiepearl/episodes/Episode-1--David-Lynch-e5akcm">Listen</a>
                                    </div>
                                </div>
                            

                                <div>
                                    <p>Susie is in conversation with David Lynch talking about the power of 
TM meditation, his daily routine, how he approaches ideas and creativity 
for his films, and what living the Art Life means to him. David talks about 
why coffee and cigarettes are important for him to get started, how to 
catch your big ideas and how to know which ideas to act on. He also tells 
a personal story about John Lennon and Maharishi in India 
and about keeping your ideas.</p>
                                </div>
                            </div>
                       </Card>
                       </ScrollAnimation>

                       <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                       <Card className="reverse">
                            <div>
                                <img src={podcast2} />
                            </div>
                            <div>
                                <div>
                                    <div className="sleeveHolder">
                                    <a href="https://anchor.fm/susiepearl/episodes/Episode-2--Ricki-Lake-e8alup"><img src={sleeve2} /></a>
                                    </div>
                                    <div>
                                        <div>
                                            <label>Episode 2</label>
                                            <h3>Ricki Lake</h3>
                                        </div>
                                        <a href="https://anchor.fm/susiepearl/episodes/Episode-2--Ricki-Lake-e8alup">Listen</a>
                                    </div>
                                </div>

                                <div>
                                    <p>Ricki is in the UK to take part in the new Celebrity X Factor show with 
Simon Cowell. Susie and Ricki have been friends for years. They talk about 
the difficulty of handling grief following the traumatic and sudden loss of 
Ricki’s husband Christian and how Ricki dealt with the tragic loss of the 
man she loved so much and how she managed to get through it all. 
They discuss the power of CBD oil and talk about Ricki’s documentary 
Weed The People. This documentary looks into the power of cannabis to 
help in curing cancer and other ailments. Susie talks about how she has 
successfully used CDB in her own cancer journey and about facing the 
stigmas that still exists around using CBD in hospitals, even though the 
research shows it helps people with so many medical issues.
</p>
                                </div>
                            </div>
                       </Card>
                       </ScrollAnimation>

                       <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                       <Card>
                            <div>
                                <img src={podcast3} />
                            </div>
                            <div>
                                <div>
                                    <div>
                                    <a href="https://anchor.fm/susiepearl/episodes/Episode-3--Jasmine-Hemsley-e97m3j"><img src={sleeve3} /></a>
                                    </div>
                                    <div>
                                        <div>
                                            <label>Episode 3</label>
                                            <h3>Jasmine Hemsley</h3>
                                        </div>
                                        <a href="https://anchor.fm/susiepearl/episodes/Episode-3--Jasmine-Hemsley-e97m3j">Listen</a>
                                    </div>
                                </div>

                                <div>
                                    <p>Jasmine and Susie have a coffee and catch up on what’s happening 
in the world of Ayurveda. They’ve been friends for 15 years and started working together when Jasmine was making soup from her kitchen table 
for Garry Barlow. Since then there have been three cookery books, 
a TV show and Jasmine has become an icon for creating a new style 
of fresh, healthy, colourful and nutritious food. She collects vintage 
fashion and interiors. Susie and Jasmine are writing a new book on living authentically and talk about what living well and healthy means to 
them both.
</p>
                                </div>
                            </div>
                       </Card>
                       </ScrollAnimation>

                       <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                       <TitleC>All Podcasts</TitleC>

                       <Archive>
                           <a href="https://anchor.fm/susiepearl/episodes/Episode-5-Gregg-Braden-ebdb8o"><img src={archive5} /></a>
                           <a href="https://anchor.fm/susiepearl/episodes/Episode-4--KT-Tunstall-eaarbf"><img src={archive4} /></a>
                           <a href="https://anchor.fm/susiepearl/episodes/Episode-3--Jasmine-Hemsley-e97m3j"><img src={archive3} /></a>
                           <a href="https://anchor.fm/susiepearl/episodes/Episode-2--Ricki-Lake-e8alup"><img src={archive2} /></a>
                           <a href="https://anchor.fm/susiepearl/episodes/Episode-1--David-Lynch-e5akcm"><img src={archive1} /></a>
                       </Archive>

                       <Signoff>Conversations with Susie Pearl podcast series is produced in London by The Radio Department.
<br/>Series Producer: Gavin Kingsley.</Signoff>

                    </ScrollAnimation>

                    </div>

					</InnerBody>
					
				</LiveInfo>
               
		
			</Page>
		)

  }

}

function mapStateToProps(state){
	return { 
	
	}
}

export default connect(mapStateToProps, {  })(ConverstationsPage);


/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Signoff = styled.p`
    font-size: 11px;
    line-height: 18px;
    margin-bottom: 100px;
`

const Archive = styled.div`
    width: 75%;
    max-width: 75%;
    margin:20px 0;
    display: flex;
    align-items: center;
    margin:auto;
    margin-bottom: 30px;

    @media only screen and (max-width: ${tablet}) {
        max-width: 100%;
        width: 100%;
    }

    a {
        display:block;
        width: 20%;
        border: 5px solid #fdf7f5;
        transition: opacity .4s ease;

        &:hover {
            opacity: .5;
            transition: opacity .4s ease;
        }
    }

    img {
        max-width: 100%;
    }

    img:first-child {
        margin-left: 0;
    }

    img:last-child {
        margin-right: 0;
    }
`

const Card = styled.div`
    border-top: 1px solid #000;
    padding-top: 30px;
    display: flex;
    width: 95%;
    max-width: 95%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin: auto;
    padding-bottom: 15px;

    @media only screen and (max-width: ${tablet}) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
     }

    
    &.reverse {
        flex-direction: row-reverse;

        @media only screen and (max-width: ${tablet}) {
            flex-wrap: wrap;
            flex-direction: column-reverse;
         }

        p {
            margin-left: 0 !important;
        }

        >div:first-child {
            margin-left: 20px;

            @media only screen and (max-width: ${tablet}) {
                margin:0 !important;
            }
        }

        .sleeveHolder {
            margin-left:0 !important;
        }
    }

    p {
        font-size: 16px;
        font-weight: normal;
        margin:0 !important;
        text-align: left;
        line-height: 1.6em;
        text-align: left !important;
        margin-bottom: 10px !important;
        margin-left: 20px !important;

        @media only screen and (max-width: ${tablet}) {
            margin:0 !important;
            margin-top: 20px !important;
            margin-bottom: 20px !important;
            line-height: 1.6em;
            font-size: 18px;
        }
    }

    img {
        max-width: 100%;
    }

    > div:first-child {
        width:30%;

        @media only screen and (max-width: ${tablet}) {
            width:100%;
         }
    }

    > div:last-child {
        width:70%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media only screen and (max-width: ${tablet}) {
            width:100%;
         }

        > div:first-child {
            display:flex;

            >div:first-child {
                max-width: 200px;
                margin: 0 20px;

                @media only screen and (max-width: ${tablet}) {
                    width:150px;
                    margin: 0 20px 0 0;
                 }
            }

            >div:last-child {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                label {
                    text-transform: uppercase;
                    font-size:.5em;
                    margin:0;
                    display: block;
                    line-height: 1em;

                    @media only screen and (max-width: ${tablet}) {
                        font-size: 11px;
                    }
                }

                h3 {
                    font-size: 30px;
                    font-weight: normal;
                    margin: 0;
                    line-height: 1.3em;

                    @media only screen and (max-width: ${tablet}) {
                        line-height:1.1em;
                        
                     }
                    
                }

                a {
                    display: block;
                    border: 1px solid #000;
                    border-radius: 30px;
                    font-size: .5em;
                    text-transform: uppercase;
                    font-weight: normal;
                    text-align:center;
                    padding: 0 10px;
                    line-height:2em;
                    margin-bottom:10px;
                    width: 85px;
                    color:#000;
                    text-decoration: none;
                    font-size: 14px;

                    @media only screen and (max-width: ${tablet}) {
                        font-size: 12px;
                        width: 65px;
                    }
                }

                a:hover {
                    background: #000;
                    color: #FDF7F4;
                    cursor: pointer;
                }
            }

        }

        > div:last-child {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
`

const Page = styled.section`
    height:100%;

   
    .cardWrapper {
        @media only screen and (max-width: ${tablet}) {
            padding: 20px;
        }
    }
`

const Wrapper = styled.section`
    padding-top:100px;
    background:#c2b0bc;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: ${tablet}) {
        padding-top: 50px;
        
     }
`

const LeadImage = styled.div`
    max-width: 100%;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 60px;

    img {
        max-width: 100%;
    }
`

const LiveInfo = styled.section`
	min-height:100%;
	padding-top:40px;
    background:#FDF7F4;
    color:#000;
    font-size:22px;
    line-height:1.6em;	
    padding-bottom: 100px;
    margin-bottom: 100%;

    .inner {
		padding:0 40px 40px 40px;
        padding-bottom:20px;
    }

	@media only screen and (max-width: ${tablet}) {
		width:100%;
        font-size:18px;
        margin-bottom: 200%;
	 }	 
`

const Banner = styled.div`
    max-width: 1100px;
    width: 100%;
    margin: auto;
    position: relative;
    padding-top: 25px;
	padding-bottom: 40px;

	@media only screen and (max-width: ${tablet}) {
		padding-bottom:0;
	 }	
	
	img {
		max-width:100%;
	}

    .inner {
        padding:0 40px 40px 40px;
    }
`


const TitleB = styled.h2`
	font-size: 100px;
	line-height:1em;
    letter-spacing:0.01em;
	margin:0;
    margin-bottom:1em;
    margin-top:30px;
	font-weight:normal;
	text-align:center;

	@media only screen and (max-width: ${tablet}) {
		font-size:40px;
		padding:0 40px;
     }

	 @media only screen and (min-width: ${desktop}) {
		font-size: 100px;
	 }

`


const TitleC = styled.h2`
	font-size: 60px;
	line-height:1em;
    letter-spacing:0.01em;
	margin:0;
    margin-bottom:30px;
    margin-top:50px;
	font-weight:normal;
    text-align:center;
    margin-top: 0;
    border-top: 1px solid #000;
    padding-top: 50px;
    max-width: 100%;
    margin: auto;
    width: 95%;
    margin-bottom: 50px;
   
    @media only screen and (max-width: ${tablet}) {
		font-size:40px;
	 }
	

	 @media only screen and (min-width: ${desktop}) {
		font-size:60px;
	 }
`

const Container = styled.section`
    padding:40px;
    padding-top:0;
    display:block;
	width:100%;
	max-width:1100px;
	margin:auto;
    padding-bottom:40px;

    @media only screen and (max-width: ${tablet}) {
        padding: 20px;
        
     }

    .introImages {
        width: 100%;
        position: relative;
        margin-top: 60px;

        img {
            max-width: 100%;
        }

        span {
            display: block;
            max-width: 100%;
            position: absolute;
            top:0;
            left:0;
        }

        img.start {
            position: relative;
        }
    }
    
    p {
        font-size: 30px;
        line-height: 1.6em;
        @media only screen and (max-width: ${tablet}) {
            font-size: 25px;
            
         }
    }

	@media only screen and (max-width: ${tablet}) {
  
        padding-bottom:0;
        
	 }
`

const Contact = styled.section`
    padding:40px;
    padding-top:0;
    display:block;
	width:100%;
	margin:auto;
	padding-bottom:80px;

	ul.contactInfo {
		margin:0;
		padding:0;

		li {
			display:block;
			width:100%;
			margin-bottom:10px;

			@media only screen and (max-width: ${tablet}) {
				margin-bottom:20px;
			 }

			strong {
				float:left;
				min-height: 50px;
				margin-right:40px;
				font-weight:normal;
				width:88px;

				@media only screen and (max-width: ${tablet}) {
					width:100%;
					min-height:10px;
				 }
			}

			div {
				float:left;
				min-height: 50px;

				em {
					display:inline-block;
					width:130px;
					font-style:normal;
				}

				a {
					text-decoration:underline;
					color:#000;
				}
			}
		}

		li:after {
			content: ' ';
			clear: both;
			display:block;
		}
	}

	@media only screen and (max-width: ${tablet}) {
		padding-bottom:40px;
	 }
`


const InnerBody = styled.div`
	max-width:1100px;
	width:100%;
    margin: auto;

    p {
        text-align:center;
        max-width: 870px;
        margin: auto;
        margin-bottom: 20px;
    }

    .intro {
        p {
            font-size: 30px;
            line-height: 1.5em;
            text-align:center;
            max-width: 760px;
            margin:auto;


            @media only screen and (max-width: ${tablet}) {
                font-size:25px;
             }	
        }
    }
`